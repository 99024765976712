.page-title-panel .page-title {
    color: #fff;
    line-height: 30px;
    font-size: 20px;
  }
  
  .page-title-panel .breadcrumb {
    background-color: transparent;
    padding: 0;
  }
  
  .page-title-panel .breadcrumb .breadcrumb-item.active {
    color: rgba(255,255,255,.6);
  }