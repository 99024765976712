body {
  margin: 0;
  font-family: Nunito,sans-serif;
  font-size: .91rem;
  font-weight: 500;
  line-height: 1.5;
  color: #505d69;
  text-align: left;
  background-color: #f4f8f9;
}

.vertical-menu {
  width: 250px;
  z-index: 1002;
  background: #fff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 70px;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08);
  box-shadow: 0 2px 4px rgba(0,0,0,.08);
}

.h-100 {
  height: 100%!important;
}

#sidebar-menu {
  padding: 10px 0 30px 0;
}

.metismenu {
  margin: 0;
}

.metismenu li {
  display: block;
  width: 100%;
}

.menu-title {
  padding: 12px 20px!important;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #7c8a96;
  font-weight: 600;
}

.metismenu li {
  display: block;
  width: 100%;
}

.mm-active {
  color: #3051d3!important;
}

#sidebar-menu ul li a {
  display: block;
  padding: .7rem 1.5rem;
  color: #7c8a96;
  position: relative;
  font-size: 15px;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.mm-active .active {
  color: #263838!important;
}
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

#sidebar-menu ul li .badge {
  margin-top: 4px;
}

body #page-topbar {
  background-color: #263838;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08);
  box-shadow: 0 2px 4px rgba(0,0,0,.08);
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(24px / 2) 0 0;
}

body .navbar-brand-box {
  background: #fff;
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: 250px;
}

body .logo-dark {
  display: block;
}
.logo {
  line-height: 70px;
}

body .header-item {
  color: #e9ecef;
}

.font-size-24 {
  font-size: 24px!important;
}

.header-item {
  height: 70px;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  color: #636e75;
  border: 0;
  border-radius: 0;
}

body .dropdown.show .header-item {
  background-color: #263838;
}

body .dropdown .header-item {
  background-color: #263838;
}


.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: #FFF;
  padding: 2px;
}

.main-content {
  margin-left: 250px;
  overflow: hidden;
}

.page-content {
   padding: 70px 12px 60px 12px;
}

.page-title-box {
  padding: 24px 12px 65px 12px;
  background-color: #263838;
  margin: 0 -12px;
  background-image: url(/assets/images/bg-pattern.png);
  background-size: cover;
}

